import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { minutesActionCreators } from '../../../stores/lis-minutes-store';
import { calendarActionCreators } from '../../../stores/lis-calendar-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { HubConnectionBuilder } from "@aspnet/signalr";
import { cancelRequest } from '../../../services/request.service';
import moment from 'moment-timezone'
import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component';
import Misc from './forms/misc';
import NonLegislativeForm from './forms/non-legislative-form';
import LegislativeForm from './forms/legislative-form';
import MinutesDetails from '../../../lis-shared/lis-minutes/lis-minutes-details';
import { memberActionCreators } from "../../../stores/lis-members-store";
import Select from 'react-select';
import ReactTable from 'react-table';
import DatePicker from 'react-datepicker';
import { billActionCreators } from "../../../stores/lis-legislation-store";
import { committeeActionCreators } from "../../../stores/lis-committee-store";
import { sessionActionCreators } from "../../../stores/lis-session-store";
import { communicationsActionCreators } from "../../../stores/lis-communications-store";

const API_URL = window.env ? window.env.API_SERVER : '';

class MinutesManagementForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activity: '',
            minutesBook: {},
            currentActivitySequence: 0,
            isPendingChange: false,
            lastSavedTime: '',
            lastPublishedTime: '',
            entryHubConnection: null,
            categoryHubConnection: null,
            isLoaded: false,
            disableChanges: false,
            //Fieldsets
            indexFieldsetIsActive: true,
            activityFieldsetIsActive: false,
            previewFieldsetIsActive: false,
            memberList: [],
            committeeList: [],
            communicationList: [],
            actionTypes: [],
            calendarCategories: [],
            categoriesToMove: [],
            selectedCategoryToMoveTo: -1,
            sessionBills: [],
            publishing: false,
            committeeMinutesBooks: [],
            chairReportCategoriesSetForRegeneration: [],
            regeneratingCategories: [],
            fetchedMinutesBookIDs: []
        }

        //Ref used to scroll down to activity section
        this.activityRef = React.createRef();
        this.entryFormRef = React.createRef();

        this.getCategoryDescriptionText = this.getCategoryDescriptionText.bind(this);
        this.entryChange = this.entryChange.bind(this);
        this.multipleEntryChange = this.multipleEntryChange.bind(this);
        this.toggleMoveEntry = this.toggleMoveEntry.bind(this);
        this.moveEntry = this.moveEntry.bind(this);
        this.bulkMoveEntry = this.bulkMoveEntry.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
        this.saveEntry = this.saveEntry.bind(this);
        this.publishAllEntries = this.publishAllEntries.bind(this);
        this.saveMinutesBook = this.saveMinutesBook.bind(this);
        this.showPreview = this.showPreview.bind(this);
        this.showActivity = this.showActivity.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.duplicateChairmansCategory = this.duplicateChairmansCategory.bind(this);
        this.receiveEntryByWebsocket = this.receiveEntryByWebsocket.bind(this);
        this.receiveCategoryByWebsocket = this.receiveCategoryByWebsocket.bind(this);
        this.toggleFetchChairs = this.toggleFetchChairs.bind(this);
        this.handleChairsReportDateChange = this.handleChairsReportDateChange.bind(this);
        this.fetchChairsReports = this.fetchChairsReports.bind(this);
        this.toggleChairsReport = this.toggleChairsReport.bind(this);
        this.regenerateChairsReportCategory = this.regenerateChairsReportCategory.bind(this);
        this.toggleEditCategoryText = this.toggleEditCategoryText.bind(this);
        this.setTempCategoryText = this.setTempCategoryText.bind(this);
    }

    toggleFieldsetCollapse(isActive) {
        this.setState(state => ({
            [isActive]: !state[isActive]
        }));
    }

    //Some categories get the type pre-pended to the description while other do not
    getCategoryDescriptionText(item) {
        if (!item) return;

        if (item.CategoryType === 'Motion') {
            return item.CategoryDescription + " to " + item.MinutesEntries[0].value + " by " + item.MinutesEntries[1].value.label + item.MinutesEntries[2].value;
        } else if (item.CategoryText) {
            if (item.CategoryText.startsWith('%')) {
                item.CategoryText = item.CategoryText.substr(1);
            }
            return item.CategoryText.replaceAll(/%+/g, " - ")
        } else {
            return (item.DisplayType ? item.CategoryType + ' - ' : '') + item.CategoryDescription;
        }
    }

    toggleEditCategoryText(save) {
        this.setState({ editingCategoryText: !this.state.editingCategoryText }, () => {
            if (save) {
                let minutesBook = { ...this.state.minutesBook };
                minutesBook.MinutesCategories[this.state.currentActivitySequence - 1].CategoryText = this.state.tempCategoryText.replaceAll(" - ", "%");
                this.saveCategory(minutesBook.MinutesCategories[this.state.currentActivitySequence - 1]);
            } else if (this.state.editingCategoryText) {
                this.setState({ tempCategoryText: this.getCategoryDescriptionText(this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1]) })
            }
        })
    }

    setTempCategoryText(val) {
        this.setState({ tempCategoryText: val })
    }

    entryChange(index, entry, sequence) {
        //Takes the sequence, which is always one more than the index, and change the values for that MinutesEntry
        let newFormData = JSON.parse(JSON.stringify(this.state.minutesBook));
        newFormData.MinutesCategories[sequence - 1].MinutesEntries[index] = entry;
        this.setState({
            minutesBook: newFormData,
            isPendingChange: true
        });
    }

    multipleEntryChange(entries, sequence) {
        let newFormData = JSON.parse(JSON.stringify(this.state.minutesBook));
        newFormData.MinutesCategories[sequence - 1].MinutesEntries = entries;
        this.setState({
            minutesBook: newFormData,
            isPendingChange: true
        });
    }

    toggleMoveEntry(open, categoryIndex, entryIndex) {
        if (open) {
            const currentCategory = this.state.minutesBook.MinutesCategories[categoryIndex];
            const applicableCategories = this.state.minutesBook.MinutesCategories.filter(cat => cat.CategoryType === currentCategory.CategoryType && cat.MinutesCategoryID !== currentCategory.MinutesCategoryID);
            this.setState({
                movingCategorySource: categoryIndex,
                movingEntrySource: entryIndex,
                categoriesToMove: applicableCategories,
                showMoveEntryDialog: open
            });
        } else {
            this.setState({
                showMoveEntryDialog: false,
                categoriesToMove: [],
                selectedCategoryToMoveTo: -1,
                movingCategorySource: -1,
                movingEntrySource: -1,
            });
        }
    }

    moveEntry(destinationCategoryIndex) {
        let sourceCategory = { ...this.state.minutesBook.MinutesCategories[this.state.movingCategorySource] }
        let sourceEntry = { ...sourceCategory.MinutesEntries[this.state.movingEntrySource] };
        let destinationCategory = this.state.minutesBook.MinutesCategories.find(cat => cat.MinutesCategoryID === this.state.categoriesToMove[destinationCategoryIndex].MinutesCategoryID);
        if (destinationCategory) {
            let newEntry = {
                ...sourceEntry,
                MinutesCategoryID: destinationCategory.MinutesCategoryID,
                Sequence: destinationCategory.MinutesEntries && destinationCategory.MinutesEntries.length > 0 ? destinationCategory.MinutesEntries[destinationCategory.MinutesEntries.length - 1].Sequence + 1 : 1
            };
            this.saveEntry(newEntry);
            this.showActivity(destinationCategory);
            this.toggleMoveEntry(false);

            //Remove the old entry from the state variable
            let minutesBook = { ...this.state.minutesBook };
            minutesBook.MinutesCategories[this.state.movingCategorySource].MinutesEntries.splice(this.state.movingEntrySource, 1);
            this.setState({
                minutesBook: minutesBook
            });
        }
    }

    bulkMoveEntry(entries, sourceCategoryIdx, destinationCategoryID) {
        const destinationCategory = this.state.minutesBook.MinutesCategories.find(mc => mc.MinutesCategoryID === destinationCategoryID)
        if (destinationCategory) {
            let minutesBook = { ...this.state.minutesBook };
            entries.forEach((entry, idx) => {
                entry.MinutesCategoryID = destinationCategoryID;
                entry.Sequence = destinationCategory.MinutesEntries && destinationCategory.MinutesEntries.length > 0 ? destinationCategory.MinutesEntries[destinationCategory.MinutesEntries.length - 1].Sequence + idx + 1 : 1;
                //Remove the old entries from the state variable
                const sourceEntryIdx = minutesBook.MinutesCategories[sourceCategoryIdx].MinutesEntries.findIndex(me => me.MinutesEntryID === entry.MinutesEntryID);
                minutesBook.MinutesCategories[sourceCategoryIdx].MinutesEntries.splice(sourceEntryIdx, 1);
            });
            this.saveEntry(entries);

            this.setState({
                minutesBook: minutesBook
            });
        }
    }

    saveEntry(entry, callback) {
        //Request model has to look like this
        const entryObject = { MinutesEntrySave: Array.isArray(entry) ? entry : [entry] };

        //Save by Websocket. Sends the jwt for auth purposes
        this.state.entryHubConnection.invoke("SaveEntry", entryObject, localStorage.getItem('LIS_ID_TOKEN'))
            .then(() => {
                if (callback) { callback(); }
            }).catch(function (err) {
                console.error(err.toString());
            });
    }

    publishAllEntries() {
        this.setState({ publishing: true }, () => {
            //make sure the minutes book itself is public
            let promise = Promise.resolve();
            if (!this.state.minutesBook.IsPublic) {
                promise = this.saveMinutesBook(true);
            }
            promise.then(() => {
                if (!this.state.minutesBook.MinutesCategories || !this.state.minutesBook.MinutesCategories.length) {
                    this.setState({ publishing: false })
                } else {
                    this.state.minutesBook.MinutesCategories.forEach((category, categoryIdx) => {
                        if (category.ReleaseToPreview) {
                            this.setState({ publishing: true }, () => {
                                category.MinutesEntries.forEach((entry, entryIdx) => {
                                    let newEntry = JSON.parse(JSON.stringify(entry));
                                    newEntry.IsPublic = true;
                                    newEntry.IsReleased = true;
                                    if (newEntry.MinutesActivities) {
                                        newEntry.MinutesActivities.forEach(activity => {
                                            activity.IsPublic = true
                                        });
                                    }
                                    this.saveEntry(newEntry, () => {
                                        if (entryIdx === category.MinutesEntries.length - 1) {
                                            this.setState({ publishing: false })
                                        }
                                    });
                                });
                            })
                        } else if (categoryIdx === this.state.minutesBook.MinutesCategories.length - 1) {
                            this.setState({ publishing: false })
                        }
                    });
                }
                // If a user is editing an activity then clicks publish the save will update the mod dates
                // Meaning that once the user tries to save their activity it will fail because the mod dates are not correct
                // So get rid of all edits
                if (this.entryFormRef.current) {
                    this.entryFormRef.current.closeNewEntries();
                }
                this.setState({
                    lastPublishedTime: moment()
                });
            }).catch(() => {
                this.setState({ publishing: false })
            })
        })
    }

    handleSequenceChange(categories) {
        //The sequence change has been handled by the MinutesIndex class. So the new list of Minute Categories is sent here to modify the minutesBook
        let minutesBook = this.state.minutesBook;
        //Check and see if the currently opened activity has been affected by the sequence change. If so, then update the current activity state item 
        let newCategoryIndex = this.state.currentActivitySequence - 1;
        if (categories[this.state.currentActivitySequence - 1] !== this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1]) {
            newCategoryIndex = categories.findIndex(category => category === this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1])
        }
        minutesBook.MinutesCategories = categories;
        //currentActivitySequence is 0 by default so we don't want to set currentActivitySequence to an undefined value. 
        //So make a check to see if the user has clicked on a category item yet. If not then leave it at zero
        this.setState({
            minutesBook: minutesBook,
            currentActivitySequence: categories[newCategoryIndex] ? categories[newCategoryIndex].Sequence : 0
        }, async function () {
            await this.saveMinutesBook()
        });
    }

    showPreview(index) {
        let minutesBook = JSON.parse(JSON.stringify(this.state.minutesBook));
        let category = minutesBook.MinutesCategories[index];
        //flips the 'show' key. this will be used in the Preview class to dictate whether a section will be shown or not
        const show = !category.ReleaseToPreview;
        category.ReleaseToPreview = show;
        this.setState({
            minutesBook: minutesBook
        }, () => {
            this.state.categoryHubConnection.invoke("SaveCategory", category, localStorage.getItem('LIS_ID_TOKEN'))
                .catch(function (err) {
                    console.error(err.toString());
                });
        });
    }

    showActivity(category, e) {
        //Check to see if the checkbox was clicked so the activity doesn't open on checkbox click
        if (!e || e.target.type !== "checkbox") {
            let minutesBook = JSON.parse(JSON.stringify(this.state.minutesBook));
            category.ReleaseToPreview = true;
            //Open the activity drawer
            this.setState({
                activityFieldsetIsActive: true,
                currentActivitySequence: category.Sequence,
                activity: category.CategoryType,
                minutesBook: minutesBook,
                editingCategoryText: false
            }, () => {
                //Get the size and position of the activity form and scroll down to it
                const activityRect = this.activityRef.current.getBoundingClientRect();
                const windowY = window.scrollY;
                window.scrollTo({
                    top: activityRect.top + windowY,
                    behavior: 'smooth'
                });

                this.saveCategory(category);
            });
        }
    }

    saveCategory(category) {
        this.setState({ categoryFormDisabled: true }, () => {
            const self = this;
            this.state.categoryHubConnection.invoke("SaveCategory", category, localStorage.getItem('LIS_ID_TOKEN'))
                .catch(function (err) {
                    console.error(err.toString());
                    self.setState({
                        categoryFormDisabled: false
                    })
                    if (err.toString().toLowerCase().includes("connection closed")) {
                        self.props.actions.makeToast([{ message: "Category Connection Disconnected", type: "failure" }])
                    }
                });
        })
    }

    async saveMinutesBook(publish) {
        //Save the new sequence
        let minutesBook = JSON.parse(JSON.stringify(this.state.minutesBook));
        if (publish) { minutesBook.IsPublic = true; }
        //Remove the minute entries so the request doesn't take as long
        minutesBook.MinutesCategories.forEach(category => delete category.MinutesEntries);
        delete minutesBook.MinutesFiles;
        //Disable changes so the user doesn't try to save something else while this is being saved
        this.setState({ disableChanges: true })
        await this.props.actions.saveMinutesBook(minutesBook)
            .then(() => {
                let minutesBook = { ...this.state.minutesBook };
                const newMinutesBook = this.props.minutes.minutesBookSave;
                minutesBook.ModificationDate = newMinutesBook.ModificationDate;
                minutesBook.MinutesCategories.forEach((category, index) => {
                    category.ModificationDate = newMinutesBook.MinutesCategories[index].ModificationDate;
                });
                this.setState({
                    minutesBook: minutesBook,
                    disableChanges: false
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.props.actions.makeToast([{ message: 'Save Failed', type: "failure" }])
            });
    }

    async createCategory(newCategory, callback) {
        this.setState({
            creatingCategory: true
        });
        //Save a blank entry to the category if there isn't one already
        newCategory.MinutesEntries = newCategory.MinutesEntries && newCategory.MinutesEntries.length ? newCategory.MinutesEntries : [{
            EntryText: '',
            Sequence: 1
        }];
        //Add motion button clicked, create a blank motion onto the list of categories
        newCategory.MinutesBookID = parseInt(this.props.match.params.minutesbookid, 10);
        await this.props.actions.createCategory(newCategory)
            .then(() => {
                let categoryCreate = this.props.minutes.categoryCreate;
                if (!categoryCreate) {
                    throw 'Category Creation Failed';
                }
                if (callback) {
                    callback(categoryCreate.MinutesCategoryID)
                }
                // Once the category is saved then scroll down to it
                // The new category is added to the book by the websocket connection so we do not have to add it here
                this.setState({
                    activity: categoryCreate.CategoryType,
                    activityFieldsetIsActive: true,
                    currentActivitySequence: categoryCreate.Sequence,
                    creatingCategory: false
                }, () => {
                    //Get the size and position of the activity form and scroll down to it
                    const activityRect = this.activityRef.current.getBoundingClientRect();
                    const windowY = window.scrollY;
                    window.scrollTo({
                        top: activityRect.top + windowY,
                        behavior: 'smooth'
                    });
                });
            })
            .catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                this.setState({
                    creatingCategory: false,
                    fetchingChairsReports: false
                });
                console.log(err)
                this.props.actions.makeToast([{ message: 'Failed to Create', type: "failure" }])
            });
    }

    async duplicateChairmansCategory(calendarCategoryTypeID) {
        let minutesBook = { ...this.state.minutesBook };
        let categoryToDuplicate = JSON.parse(JSON.stringify(minutesBook.MinutesCategories.find(mc => mc.CalendarCategoryTypeID === calendarCategoryTypeID)));
        //set sequence
        categoryToDuplicate.Sequence = minutesBook.MinutesCategories.length + 1;
        //delete mod dates, IDs, etc
        delete categoryToDuplicate.MinutesCategoryID;
        delete categoryToDuplicate.ModificationDate;
        categoryToDuplicate.MinutesEntries.forEach(entry => {
            delete entry.MinutesEntryID;
            delete entry.MinutesCategoryID;
            delete entry.ModificationDate;
            entry.MinutesSummaries.forEach(summary => {
                delete summary.MinutesSummaryID;
                delete summary.MinutesEntryID;
                delete summary.ModificationDate;
            })
            entry.MinutesActivities.forEach(act => {
                delete act.MinutesSummaryID;
                delete act.MinutesEntryID;
                delete act.ModificationDate;
            })
        })

        await this.createCategory(categoryToDuplicate);
    }

    receiveEntryByWebsocket(result) {
        let minutesBook = JSON.parse(JSON.stringify(this.state.minutesBook));
        result.forEach(entryFromResult => {
            let categoryIndex = -1;
            let entryIndex = -1;
            categoryIndex = minutesBook.MinutesCategories.findIndex(category => category.MinutesCategoryID === entryFromResult.MinutesCategoryID);
            //If an object is not found in the array then don't continue. The entry is probably in another book.
            if (categoryIndex !== -1) {
                entryIndex = minutesBook.MinutesCategories[categoryIndex].MinutesEntries.findIndex(entry => entry.MinutesEntryID === entryFromResult.MinutesEntryID);
                entryFromResult.MinutesActivities = entryFromResult.MinutesActivities || [];
                if (entryIndex !== -1) {
                    minutesBook.MinutesCategories[categoryIndex].MinutesEntries[entryIndex] = entryFromResult;
                } else {
                    let minuteEntries = minutesBook.MinutesCategories[categoryIndex].MinutesEntries || [];
                    minuteEntries.push(entryFromResult);
                    minuteEntries.sort((a, b) => a.Sequence - b.Sequence);
                }
            }
        })
        this.setState({
            minutesBook: minutesBook
        });
    }

    receiveCategoryByWebsocket(result) {
        //Sometimes we'll receive a category that hasn't been updated that has the wrong sequence values    
        result[0].MinutesEntries.sort((a, b) => a.Sequence - b.Sequence);

        let minutesBook = JSON.parse(JSON.stringify(this.state.minutesBook));
        result.forEach(categoryFromResult => {
            // If these are not equal then the message is for another book
            if (categoryFromResult.MinutesBookID === minutesBook.MinutesBookID) {
                let categoryIndex = -1;
                //Sometimes these arrays can be undefined if they have no data. Make sure that they are arrays so they don't cause problems down the line.
                categoryFromResult.MinutesEntries = categoryFromResult.MinutesEntries || [];
                categoryFromResult.MinutesEntries.forEach(entry => entry.MinutesActivities = entry.MinutesActivities || []);

                categoryIndex = minutesBook.MinutesCategories.findIndex(category => category.MinutesCategoryID === categoryFromResult.MinutesCategoryID);
                if (categoryIndex !== -1) {
                    minutesBook.MinutesCategories[categoryIndex] = categoryFromResult;
                } else {
                    minutesBook.MinutesCategories.push(categoryFromResult);
                    minutesBook.MinutesCategories.sort((a, b) => a.Sequence - b.Sequence);
                }
            }
        })
        this.setState({
            minutesBook: minutesBook,
            categoryFormDisabled: false
        });
    }

    componentDidMount() {
        this.props.actions.getMinutesBook('?minutesbookID=' + this.props.match.params.minutesbookid, true)
            .then(() => {
                let minutesBook = this.props.minutes.minutesBook;
                if (minutesBook.MinutesCategories) {
                    //Check if the Sequence for each category is linear
                    const sequences = minutesBook.MinutesCategories.map(mc => mc.Sequence);
                    if (minutesBook.MinutesCategories.length > 0 && (minutesBook.MinutesCategories[0].Sequence !== 1 || !sequences.every((seq, i) => i === sequences.length - 1 || seq + 1 === sequences[i + 1]))) {
                        //Get the sequence right. Sometimes the result back from the server has a lot of skipped indices
                        minutesBook.MinutesCategories.forEach((category, index) => {
                            category.Sequence = index + 1;
                        });
                        this.setState({
                            minutesBook: minutesBook,
                            isLoaded: true,
                            previewFieldsetIsActive: true
                        }, //Save the new sequence so we don't have to do this process again for this minutesbook
                            async () => { await this.saveMinutesBook(); });
                    } else { //It is in order so display the book
                        this.setState({
                            minutesBook: minutesBook,
                            isLoaded: true,
                            previewFieldsetIsActive: true
                        });
                    }
                    // set fetchedMinutesBookIDs for the chairs report logic (see the fetchChairsReports method)
                    let fetchedMinutesBookIDs = [];
                    minutesBook.MinutesCategories.forEach(category => {
                        if (category.CategoryType === "Chairmans Report" && category.MinutesEntries) {
                            category.MinutesEntries.forEach(entry => {
                                if (entry.MinutesSummaries) {
                                    fetchedMinutesBookIDs = fetchedMinutesBookIDs.concat(entry.MinutesSummaries.filter(ms => ms.ReferenceID).flatMap(ms => ms.ReferenceID));
                                }
                            })
                        }
                    })
                    fetchedMinutesBookIDs = [...new Set(fetchedMinutesBookIDs)];
                    this.setState({ fetchedMinutesBookIDs });
                } else {
                    this.setState({
                        minutesBook: '',
                        isLoaded: true,
                        previewFieldsetIsActive: false
                    });
                    this.props.actions.makeToast([{ message: "Loading Failed", type: "failure" }])
                }
                // This is used to add members to the motion forms
                this.props.actions.getMemberList('sessionID=' + minutesBook.SessionID + '&chamberCode=S').then(() => {
                    let memberList = [...this.props.members.memberList];
                    memberList.forEach(member => {
                        member.label = member.PatronDisplayName;
                        member.value = member.MemberID;
                    });
                    this.setState({
                        memberList: memberList
                    });
                });
                // Have to get the session info in order to get the effective date for the committee list
                this.props.actions.getSessionById(minutesBook.SessionID)
                    .then(() => {
                        this.props.actions.getCommitteeList('sessionID=' + this.props.session.selectedSession.SessionID + "&includeSubCommittees=true").then(() => {
                            let committeeList = this.props.committee.committeeList;
                            committeeList.forEach(committee => {
                                committee.label = committee.Name;
                                committee.value = committee.CommitteeID;
                            });
                            this.setState({
                                committeeList: committeeList
                            });
                        });
                        //get communication summary list for communication action reference pickers
                        this.props.actions.getCommunicationSummaryList('sessionID=' + this.props.session.selectedSession.SessionID + "&communicationTypeID=1&chamberCode=H").then(() => {
                            let communicationList = this.props.communications.communicationSummaryList;
                            //summaries that have already been selected/used will not be returned from this endpoint, but they need to be in the dropdown in order for that selected option to appear as selected, so add these manually to the list
                            const usedCommunicationSummaries = [...this.state.minutesBook.MinutesCategories].filter(mc => mc.MinutesEntries && mc.MinutesEntries.find(me => me.MinutesActivities && me.MinutesActivities.find(ma => ma.ActivityReferences && ma.ActivityReferences.find(ar => ar.ActionReferenceType === "Communication" && ar.ReferenceID)))).flatMap(mc => mc.MinutesEntries.filter(me => me.MinutesActivities && me.MinutesActivities.find(ma => ma.ActivityReferences && ma.ActivityReferences.find(ar => ar.ActionReferenceType === "Communication" && ar.ReferenceID)))).flatMap(me => me.MinutesActivities.filter(ma => ma.ActivityReferences && ma.ActivityReferences.find(ar => ar.ActionReferenceType === "Communication" && ar.ReferenceID))).flatMap(ma => ma.ActivityReferences.filter(ar => ar.ActionReferenceType === "Communication" && ar.ReferenceID));
                            usedCommunicationSummaries.forEach(usedCommunicationSummary => {
                                if (!communicationList.find(communication => communication.CommunicationID === usedCommunicationSummary.ReferenceID)) {
                                    const minutesEntry = [...this.state.minutesBook.MinutesCategories].find(mc => mc.MinutesEntries && mc.MinutesEntries.find(me => me.MinutesActivities && me.MinutesActivities.find(ma => ma.MinutesActivityID === usedCommunicationSummary.MinutesActivityID))).MinutesEntries.find(me => me.MinutesActivities && me.MinutesActivities.find(ma => ma.MinutesActivityID === usedCommunicationSummary.MinutesActivityID));
                                    const communicationSummaries = minutesEntry.MinutesSummaries ? minutesEntry.MinutesSummaries.filter(ms => ms.ReferenceID === usedCommunicationSummary.ReferenceID).map(ms => ({ ...ms, CommunicationID: ms.ReferenceID })) : [];
                                    communicationList.push({ ReferenceNumber: usedCommunicationSummary.ReferenceText, CommunicationID: usedCommunicationSummary.ReferenceID, CommunicationSummaries: communicationSummaries, CommunicationDate: minutesEntry.CommunicationDate, CommunicationNumber: minutesEntry.CommunicationNumber })
                                    //sort list by CommunicationDate and CommunicationNumber, if not present then use the ReferenceNumber to determine the Date/Number
                                    communicationList.sort((a, b) => a.CommunicationDate && b.CommunicationDate ?
                                        moment(b.CommunicationDate).isAfter(moment(a.CommunicationDate), 'day') ? 1 :
                                            moment(b.CommunicationDate).isBefore(moment(a.CommunicationDate), 'day') ? -1 :
                                                Number(a.CommunicationNumber) - Number(b.CommunicationNumber)
                                        : a.ReferenceNumber && b.ReferenceNumber && a.ReferenceNumber.length >= 7 && b.ReferenceNumber.length >= 7 ?
                                            Number(b.ReferenceNumber.substring(b.ReferenceNumber.length - 4)) - Number(a.ReferenceNumber.substring(a.ReferenceNumber.length - 4))
                                            : b.ReferenceNumber - a.ReferenceNumber)
                                }
                            })
                            communicationList.forEach(communication => {
                                communication.label = communication.ReferenceNumber;
                                communication.value = communication.CommunicationID;
                            });
                            this.setState({
                                communicationList: communicationList
                            });
                        });
                    })
                // Used to apply a legislation number to an entry
                this.props.actions.getBillNumbers('?sessionID=' + minutesBook.SessionID).then(() => {
                    let billNumbers = this.props.bills.billNumbers;
                    billNumbers.forEach(bill => {
                        bill.label = bill.LegislationNumber;
                        bill.value = bill.LegislationNumber;
                    })
                    this.setState({
                        sessionBills: billNumbers
                    });
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
                console.log(err);
                this.setState({
                    minutesBook: '',
                    isLoaded: true,
                });
            });

        this.props.actions.getCalendarCategoriesReferences('?Chambercode=S', true)
            .then(() => {
                this.setState({
                    calendarCategories: this.props.calendar.calendarCategoriesReferences
                });
            });

        const getConnectionId = (conn, connectionName) => {
            //This connection ID might be used later
            conn.invoke('getConnectionId')
                .then(connectionId => {
                    console.log(`${connectionName}: ${connectionId}`);
                    this.setState({
                        [connectionName]: connectionId
                    });
                });
        }
        //When a WebSocket message comes across with this label then the minutesbook object needs to be updated with new values
        const handleMessage = (message, functionToRun) => {
            try {
                //the Value key holds error info when a save has failed
                if (message.StatusCode > 399) throw 'Server Error: ' + JSON.stringify(message);
                //Some reason the Value key doesn't parse. So I do it again here
                let result;
                if (message.Value)
                    result = JSON.parse(message.Value);
                else
                    result = message;
                functionToRun(result)
                this.setState({
                    lastSaveErr: '',
                    lastSavedTime: moment(),
                    isPendingChange: false
                });
            } catch (err) {
                console.log(err);
                this.setState({
                    lastSaveErr: err.toString(),
                    isPendingChange: false
                });
            }
        };

        //A second function is used because the JSON will look different depending on whether the data was
        //originally saved by Websocket or by HTTP
        const handleMessageOriginatedFromWebsocket = (message, functionToRun) => {
            try {
                //the Value key holds error info when a save has failed
                if (!message.Success) throw 'Server Error: ' + JSON.stringify(message);
                //Some reason the Value key doesn't parse. So I do it again here
                let result;
                if (message.Message)
                    result = JSON.parse(message.Message);
                else
                    result = message;
                functionToRun(result)
                this.setState({
                    lastSaveErr: '',
                    lastSavedTime: moment(),
                    isPendingChange: false
                });
            } catch (err) {
                console.log(err);
                this.setState({
                    lastSaveErr: 'Error: save failed',
                    isPendingChange: false
                });
            }

        };

        let entryConnection = new HubConnectionBuilder()
            .withUrl((API_URL || process.env.REACT_APP_MINUTES_ENTRY_API_URL) + '/MinuteEntries/entries')
            .build();
        //Will change to a more reasonable timeout later
        entryConnection.serverTimeoutInMilliseconds = 5000000000;
        this.setState({
            entryHubConnection: entryConnection
        }, () => {
            this.state.entryHubConnection
                .start({ withCredentials: false })
                .then(() => {
                    getConnectionId(this.state.entryHubConnection, 'entryConnectionId');
                }).catch(err => {
                    console.log('Error while establishing connection :( ' + err)
                    this.props.actions.makeToast([{ message: "Entry Connection Failed", type: "failure" }])
                });
            this.state.entryHubConnection.on("ReceiveMinuteEntry", message => handleMessage(message, this.receiveEntryByWebsocket));
            this.state.entryHubConnection.on("ReceiveEntry", message => handleMessage(message, this.receiveEntryByWebsocket));
            this.state.entryHubConnection.on("Receive", message => handleMessageOriginatedFromWebsocket(message, this.receiveEntryByWebsocket));
        });

        let categoryConnection = new HubConnectionBuilder()
            .withUrl((API_URL || process.env.REACT_APP_MINUTES_API_URL) + '/MinutesBook/categories')
            .build();
        //Will change to a more reasonable timeout later
        categoryConnection.serverTimeoutInMilliseconds = 5000000000;
        this.setState({
            categoryHubConnection: categoryConnection
        }, () => {
            this.state.categoryHubConnection
                .start({ withCredentials: false })
                .then(() => {
                    getConnectionId(this.state.categoryHubConnection, 'categoryConnectionId');
                }).catch(err => {
                    console.log('Error while establishing connection :( ' + err)
                    this.props.actions.makeToast([{ message: "Category Connection Failed", type: "failure" }])
                });

            this.state.categoryHubConnection.on("ReceiveCategory", message => handleMessage(message, this.receiveCategoryByWebsocket));
            this.state.categoryHubConnection.on("Receive", message => handleMessageOriginatedFromWebsocket(message, this.receiveCategoryByWebsocket))
        });

        // These are the different form inputs that the user can pick from when picking a motion
        this.props.actions.getActionTypes()
            .then(() => {
                let actionTypes = [...this.props.minutes.actionTypes];
                actionTypes.forEach(type => {
                    type.label = type.ActionReferenceType;
                    type.value = type.ActionReferenceTypeID;
                })
                this.setState({
                    actionTypes: actionTypes
                });
            });
    }

    toggleFetchChairs() {
        this.setState({ fetchChairsReports: !this.state.fetchChairsReports })
    }

    handleChairsReportDateChange(date, key) {
        if (date) {
            if (key === "START") {
                this.setState({
                    chairsReportStartDate: moment(date).startOf('day'),
                    chairsReportEndDate: this.state.chairsReportEndDate || moment(date).endOf('day')
                })
            } else {
                this.setState({
                    chairsReportEndDate: moment(date).endOf('day')
                })
            }
        }
    }

    fetchChairsReports() {
        this.setState({ fetchingChairsReports: true, disableChanges: true }, () => {
            this.props.actions.getMinutesList('sessionID=' + this.state.minutesBook.SessionID + '&startDate=' + moment(this.state.chairsReportStartDate).format("MM/DD/yyyy") + '&endDate=' + this.state.chairsReportEndDate.format("MM/DD/yyyy"), false).then(() => {
                if (this.props.minutes.minutesList) {
                    const committeeMinutesBooks = this.props.minutes.minutesList.filter(c => c.ChamberCode === 'S' && c.CommitteeID && c.MinutesStatus && c.MinutesStatus.toLowerCase() === 'closed');
                    if (committeeMinutesBooks.length) {
                        //combine the minutes books from this date range with the minutes books already fetched from previous date ranges
                        const committeeMinutesBookIDs = committeeMinutesBooks.map(c => c.MinutesBookID);
                        const combinedMinutesBookIDs = committeeMinutesBookIDs.concat([...this.state.fetchedMinutesBookIDs]);
                        const uniqueCombinedMinutesBookIDs = [...new Set(combinedMinutesBookIDs)];
                        this.props.actions.getCommitteeMinutesSummary('?' + uniqueCombinedMinutesBookIDs.map((id, idx) => (idx ? '&' : '') + 'minutesBookIDs=' + id).join('')).then(async () => {
                            this.setState({ fetchedMinutesBookIDs: uniqueCombinedMinutesBookIDs })
                            let minutesBook = { ...this.state.minutesBook };
                            minutesBook.MinutesCategories = minutesBook.MinutesCategories || [];
                            let committeeMinutesSummary = this.props.minutes.committeeMinutesSummary;
                            let totalIterations = 0;
                            let newIterations = 0;
                            let idx = 0;
                            for (const category of committeeMinutesSummary) {
                                if (minutesBook.MinutesCategories.find(mc => mc.CalendarCategoryTypeID === category.CalendarCategoryTypeID)) {
                                    let matchingIterations = 0;
                                    //update the existing categories that are of this same type
                                    minutesBook.MinutesCategories.filter(mc => mc.CalendarCategoryTypeID === category.CalendarCategoryTypeID).forEach((existingMatchingCategory, matchingCategoryIdx) => {
                                        const existingCategoryIndex = minutesBook.MinutesCategories.findIndex(mc => mc.MinutesCategoryID === existingMatchingCategory.MinutesCategoryID);
                                        minutesBook.MinutesCategories[existingCategoryIndex].CategoryText = category.CategoryDescription;
                                        minutesBook.MinutesCategories[existingCategoryIndex].CategoryDescription = category.CategoryDescription;
                                        category.MinutesEntries.forEach(entry => {
                                            //for each entry (i.e. cmte) of this category from the response, check if the entry (i.e. committee) already exists
                                            const cmte = entry.MinutesSummaries && entry.MinutesSummaries.find(ms => ms.CommitteeID);
                                            if (cmte) {
                                                const existingMatchingEntryIndex = existingMatchingCategory.MinutesEntries.findIndex(me => me.MinutesSummaries && me.MinutesSummaries.find(ms => ms.CommitteeID && ms.CommitteeID === cmte.CommitteeID));
                                                if (existingMatchingEntryIndex > -1) {
                                                    //entry/cmte already exists - add new summaries/ignore existing ones, and update the entrytext regardless
                                                    //iterate over each summary returned from the response, check to see if the summary's ReferenceID (minutes book) is already in this entry.
                                                    //if it is, we already have this summary, so just update the MinutesSummary text attr since it may have new bills in it. If we don't have this summary at all, add it
                                                    entry.MinutesSummaries.forEach(summary => {
                                                        const existingMatchingSummaryIndex = existingMatchingCategory.MinutesEntries[existingMatchingEntryIndex].MinutesSummaries.findIndex(ms => ms.ReferenceID === summary.ReferenceID);
                                                        if (existingMatchingSummaryIndex > -1) {
                                                            minutesBook.MinutesCategories[existingCategoryIndex].MinutesEntries[existingMatchingEntryIndex].MinutesSummaries[existingMatchingSummaryIndex].MinutesSummary = summary.MinutesSummary
                                                        } else {
                                                            minutesBook.MinutesCategories[existingCategoryIndex].MinutesEntries[existingMatchingEntryIndex].MinutesSummaries.push(summary);
                                                        }
                                                    })
                                                    minutesBook.MinutesCategories[existingCategoryIndex].MinutesEntries[existingMatchingEntryIndex].EntryText = entry.EntryText;
                                                } else {
                                                    //entry/cmte is new/doesn't already exist - add the new entry/cmte
                                                    minutesBook.MinutesCategories[existingCategoryIndex].MinutesEntries.push(entry);
                                                }
                                            }
                                        })
                                        //save category with updated/new entries
                                        this.state.categoryHubConnection.invoke("SaveCategory", minutesBook.MinutesCategories[existingCategoryIndex], localStorage.getItem('LIS_ID_TOKEN'))
                                            .then(() => {
                                                //the EntryText of all the entries for each of these categories will now include even the excluded chairs reports again. Use the regenerate endpt since this will take that into account
                                                this.regenerateChairsReportCategory(minutesBook.MinutesCategories[existingCategoryIndex].Sequence - 1, () => {
                                                    matchingIterations++;
                                                    if (matchingIterations === minutesBook.MinutesCategories.filter(mc => mc.CalendarCategoryTypeID === category.CalendarCategoryTypeID).length) {
                                                        totalIterations++;
                                                    }
                                                    if (totalIterations === committeeMinutesSummary.length && matchingIterations === minutesBook.MinutesCategories.filter(mc => mc.CalendarCategoryTypeID === category.CalendarCategoryTypeID).length) {
                                                        this.setState({ fetchingChairsReports: false, disableChanges: false })
                                                    }
                                                });
                                            }).catch(function (err) {
                                                console.error(err.toString());
                                                if (idx === committeeMinutesSummary.length - 1) {
                                                    this.setState({ fetchingChairsReports: false, disableChanges: false })
                                                }
                                            });
                                    })
                                } else {
                                    //no existing categories are of this same type, create the category as new
                                    newIterations++;
                                    category.ReleaseToPreview = true;
                                    category.CategoryText = category.CategoryDescription;
                                    category.Sequence = minutesBook.MinutesCategories.length + newIterations;
                                    await this.createCategory(category, () => {
                                        if (idx === committeeMinutesSummary.length - 1) {
                                            this.setState({ fetchingChairsReports: false, disableChanges: false })
                                        }
                                    });
                                }
                                idx++;
                            }
                        }).catch(err => {
                            if (err === 'Aborted') {
                                return;
                            }
                            console.log(err);
                            this.setState({
                                fetchingChairsReports: false,
                                disableChanges: false
                            })
                            this.props.actions.makeToast([{ message: 'Failed to get chairs report summary', type: "failure" }])
                        });
                    } else {
                        this.setState({ fetchingChairsReports: false, disableChanges: false })
                    }
                } else {
                    this.setState({ fetchingChairsReports: false, disableChanges: false })
                }
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.setState({ fetchingChairsReports: false, disableChanges: false })
                this.props.actions.makeToast([{ message: 'Failed to get Chairs Reports', type: "failure" }])
            });
        })
    }

    toggleChairsReport(activitySequence, entryIndex, summaryIndex) {
        let minutesBook = { ...this.state.minutesBook };
        minutesBook.MinutesCategories[activitySequence].MinutesEntries[entryIndex].MinutesSummaries[summaryIndex].IsActive = !minutesBook.MinutesCategories[activitySequence].MinutesEntries[entryIndex].MinutesSummaries[summaryIndex].IsActive

        let chairReportCategoriesSetForRegeneration = [...this.state.chairReportCategoriesSetForRegeneration];
        if (!chairReportCategoriesSetForRegeneration.find(c => c === minutesBook.MinutesCategories[activitySequence].MinutesCategoryID)) {
            chairReportCategoriesSetForRegeneration.push(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID);
        }

        this.setState({ minutesBook, chairReportCategoriesSetForRegeneration })
    }

    regenerateChairsReportCategory(activitySequence, callback) {
        let minutesBook = { ...this.state.minutesBook };
        let chairReportCategoriesSetForRegeneration = [...this.state.chairReportCategoriesSetForRegeneration];
        let regeneratingCategories = [...this.state.regeneratingCategories];
        regeneratingCategories.push(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID);
        this.setState({ regeneratingCategories }, () => {
            const committeeMinutesBookIDs = minutesBook.MinutesCategories[activitySequence].MinutesEntries.flatMap(me => me.MinutesSummaries).filter(ms => ms.IsActive && ms.ReferenceID).map(ms => ms.ReferenceID);
            if (committeeMinutesBookIDs.length) {
                this.props.actions.getCommitteeMinutesSummary('?' + committeeMinutesBookIDs.map((id, idx) => (idx ? '&' : '') + 'minutesBookIDs=' + id).join('')).then(() => {
                    let committeeMinutesSummary = this.props.minutes.committeeMinutesSummary.find(c => c.CalendarCategoryTypeID === minutesBook.MinutesCategories[activitySequence].CalendarCategoryTypeID);
                    minutesBook.MinutesCategories[activitySequence].MinutesEntries.forEach((entry, idx) => {
                        const associatedCommitteeMinutesEntry = committeeMinutesSummary.MinutesEntries.find(me => me.MinutesSummaries.find(ms => ms.CommitteeID && entry.MinutesSummaries.find(existingMs => existingMs.IsActive && ms.CommitteeID === existingMs.CommitteeID)))
                        if (associatedCommitteeMinutesEntry) {
                            minutesBook.MinutesCategories[activitySequence].MinutesEntries[idx].EntryText = associatedCommitteeMinutesEntry.EntryText;
                        } else {
                            minutesBook.MinutesCategories[activitySequence].MinutesEntries[idx].EntryText = '';
                        }
                    })
                    this.state.categoryHubConnection.invoke("SaveCategory", minutesBook.MinutesCategories[activitySequence], localStorage.getItem('LIS_ID_TOKEN'))
                        .catch(function (err) {
                            console.error(err.toString());
                        }).finally(() => {
                            //refresh these since the method can be run multiple times at once
                            chairReportCategoriesSetForRegeneration = [...this.state.chairReportCategoriesSetForRegeneration];
                            regeneratingCategories = [...this.state.regeneratingCategories];
                            chairReportCategoriesSetForRegeneration.splice(chairReportCategoriesSetForRegeneration.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                            regeneratingCategories.splice(regeneratingCategories.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                            this.setState({ regeneratingCategories, chairReportCategoriesSetForRegeneration })
                            if (callback) {
                                callback();
                            }
                        });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err);
                    //refresh these since the method can be run multiple times at once
                    chairReportCategoriesSetForRegeneration = [...this.state.chairReportCategoriesSetForRegeneration];
                    regeneratingCategories = [...this.state.regeneratingCategories];
                    chairReportCategoriesSetForRegeneration.splice(chairReportCategoriesSetForRegeneration.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                    regeneratingCategories.splice(regeneratingCategories.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                    this.setState({
                        regeneratingCategories,
                        chairReportCategoriesSetForRegeneration
                    })
                    this.props.actions.makeToast([{ message: 'Failed to get chairs report summary', type: "failure" }])
                    if (callback)
                        callback();
                });
            } else {
                minutesBook.MinutesCategories[activitySequence].MinutesEntries.forEach(entry => {
                    entry.EntryText = '';
                })
                //refresh these since the method can be run multiple times at once
                chairReportCategoriesSetForRegeneration = [...this.state.chairReportCategoriesSetForRegeneration];
                regeneratingCategories = [...this.state.regeneratingCategories];
                chairReportCategoriesSetForRegeneration.splice(chairReportCategoriesSetForRegeneration.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                regeneratingCategories.splice(regeneratingCategories.indexOf(minutesBook.MinutesCategories[activitySequence].MinutesCategoryID), 1);
                this.setState({ regeneratingCategories, chairReportCategoriesSetForRegeneration })
                if (callback)
                    callback();
            }
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <div className="user-forms dlas-forms">
                    <div className="flex-row">
                        <h2>{moment(this.state.minutesBook.MinutesDate).format("LL")}</h2>
                        <div style={{ height: '30px' }}>
                            {this.state.fetchChairsReports ?
                                <div className="flex-row flex-vertical-center" style={{ gap: '10px', alignItems: 'stretch' }}>
                                    <button className="icon delete" style={{ flexShrink: '0', margin: 'auto' }} onClick={this.toggleFetchChairs} />
                                    <div style={{ width: '165px' }}>
                                        <label>Start Date</label>
                                        <DatePicker
                                            selected={this.state.chairsReportStartDate}
                                            onChange={(val) => this.handleChairsReportDateChange(val, "START")}
                                            className='event-date-picker'
                                        />
                                    </div>
                                    <div style={{ width: '165px' }}>
                                        <label>End Date</label>
                                        <DatePicker
                                            selected={this.state.chairsReportEndDate}
                                            onChange={(val) => this.handleChairsReportDateChange(val, "END")}
                                            className='event-date-picker'
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'end' }}>
                                        <button className="button" disabled={!this.state.chairsReportStartDate || !this.state.chairsReportEndDate || this.state.fetchingChairsReports || this.state.regeneratingCategories.length} onClick={this.fetchChairsReports}>{this.state.fetchingChairsReports ? <div className="small-spinner" /> : 'Go'}</button>
                                    </div>
                                </div>
                                :
                                <button className="button" onClick={this.toggleFetchChairs}>Fetch Chair's Reports</button>
                            }
                        </div>
                    </div>
                    {this.state.showMoveEntryDialog &&
                        <div className='popup' onClick={() => this.toggleMoveEntry(false)}>
                            <div className='popup-inner tall-content' onClick={e => e.stopPropagation()}>
                                {this.state.categoriesToMove.length === 0 ?
                                    <p>There are no categories where you can move this entry</p>
                                    :
                                    <p>Choose a category to move the entry to</p>
                                }
                                <div style={{ paddingBottom: '10px' }}>
                                    <ReactTable
                                        data={this.state.categoriesToMove}
                                        getTrProps={(_state, rowInfo) => {
                                            return {
                                                style: {
                                                    background: rowInfo && rowInfo.index === this.state.selectedCategoryToMoveTo ? '#89ff89' : '',
                                                    cursor: rowInfo ? 'pointer' : 'default'
                                                },
                                                onClick: () => this.setState({ selectedCategoryToMoveTo: rowInfo.index })
                                            }
                                        }}
                                        noDataText=""
                                        columns={[
                                            {
                                                id: "checkbox",
                                                accessor: "",
                                                className: "checkbox-column",
                                                Cell: ({ index }) => {
                                                    return <span className={index === this.state.selectedCategoryToMoveTo ? "icon checkmark" : ""}></span>;
                                                },
                                                sortable: false,
                                                filterable: false,
                                                width: 45
                                            },
                                            {
                                                Header: "Sequence",
                                                accessor: "Sequence",
                                                width: 50
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "CategoryType"
                                            },
                                            {
                                                Header: "Description",
                                                accessor: "CategoryDescription"
                                            }
                                        ]}
                                        defaultPageSize={5}
                                        className="-striped -highlight"
                                    />
                                </div>
                                <div className="flex-row" sty>
                                    <button className="button secondary" onClick={() => this.toggleMoveEntry(false)}>Cancel</button>
                                    {this.state.categoriesToMove.length > 0 &&
                                        <button disabled={this.state.selectedCategoryToMoveTo === -1} onClick={() => this.moveEntry(this.state.selectedCategoryToMoveTo)} className="button">Move</button>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <fieldset className={this.state.indexFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'indexFieldsetIsActive')}>Index</legend>
                        <div>
                            <MinutesIndex
                                categories={this.state.minutesBook.MinutesCategories ? this.state.minutesBook.MinutesCategories : []}
                                onSequenceChange={this.handleSequenceChange}
                                showPreview={this.showPreview}
                                showActivity={this.showActivity}
                                createCategory={this.createCategory}
                                duplicateChairmansCategory={this.duplicateChairmansCategory}
                                getCategoryDescriptionText={this.getCategoryDescriptionText}
                                creatingCategory={this.state.creatingCategory}
                                calendarCategories={this.state.calendarCategories}
                                currentActivitySequence={this.state.currentActivitySequence}
                                disableChanges={this.state.disableChanges} />
                        </div>
                    </fieldset>
                    <fieldset ref={this.activityRef} className={this.state.activityFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'activityFieldsetIsActive')}>Activity</legend>
                        <div className="flex-row">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                {!this.state.editingCategoryText ?
                                    <>
                                        <p className="small-header">{this.state.currentActivitySequence !== 0 && this.getCategoryDescriptionText(this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1])}</p>
                                        {this.state.currentActivitySequence !== 0 && <a className="icon edit" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryText()} />}
                                    </>
                                    :
                                    <>
                                        <input style={{ width: '50%' }} value={this.state.tempCategoryText} onChange={(e) => this.setTempCategoryText(e.target.value)} />
                                        <a className="icon save" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryText(true)} />
                                        <a className="icon delete" style={{ marginLeft: '7px' }} onClick={() => this.toggleEditCategoryText()} />
                                    </>
                                }
                            </div>
                            {this.state.currentActivitySequence && this.state.currentActivitySequence !== 0 && this.state.minutesBook.MinutesCategories && this.state.chairReportCategoriesSetForRegeneration.find(categoryID => categoryID === this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1].MinutesCategoryID) ?
                                <div className="flex-row flex-vertical-center">
                                    <button disabled={this.state.fetchingChairsReports || this.state.regeneratingCategories.find(categoryID => categoryID === this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1].MinutesCategoryID)}
                                        onClick={() => this.regenerateChairsReportCategory(this.state.currentActivitySequence - 1)}
                                        type="button" className="button">{this.state.regeneratingCategories.find(categoryID => categoryID === this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1].MinutesCategoryID) ? <div className="small-spinner" /> : "Regenerate Summary"}</button>
                                </div>
                                : null}
                        </div>
                        {this.state.currentActivitySequence && this.state.currentActivitySequence !== 0 ?
                            <TypeSwitcher
                                type={this.state.activity}
                                ref={this.entryFormRef}
                                onEntryChange={this.entryChange}
                                onMultipleEntryChange={this.multipleEntryChange}
                                currentActivitySequence={this.state.currentActivitySequence}
                                handleSaveEntry={this.saveEntry}
                                sessionId={this.state.minutesBook.SessionID}
                                sessionCode={this.state.minutesBook.SessionCode}
                                memberList={this.state.memberList}
                                committeeList={this.state.committeeList}
                                communicationList={this.state.communicationList}
                                actionTypes={this.state.actionTypes}
                                toggleMoveEntry={this.toggleMoveEntry}
                                bulkMoveEntry={this.bulkMoveEntry}
                                sessionBills={this.state.sessionBills}
                                toggleChairsReport={this.toggleChairsReport}
                                chairReportCategoriesSetForRegeneration={this.state.chairReportCategoriesSetForRegeneration}
                                formData={this.state.minutesBook.MinutesCategories ? this.state.minutesBook.MinutesCategories[this.state.currentActivitySequence - 1] : {}}
                                categories={this.state.minutesBook.MinutesCategories || []}
                                getCategoryDescriptionText={this.getCategoryDescriptionText}
                                createCategory={this.createCategory}
                                disableChanges={this.state.disableChanges}
                                categoryFormDisabled={this.state.categoryFormDisabled} />
                            : null}
                    </fieldset>
                    <fieldset className={this.state.previewFieldsetIsActive ? 'fieldset-collapse fieldset-open' : 'fieldset-collapse fieldset-closed'} >
                        <legend onClick={this.toggleFieldsetCollapse.bind(this, 'previewFieldsetIsActive')}>Preview</legend>
                        <Preview
                            categories={this.state.minutesBook.MinutesCategories ? this.state.minutesBook.MinutesCategories : []}
                            isPendingChange={this.state.isPendingChange}
                            lastSaveErr={this.state.lastSaveErr}
                            lastSavedTime={this.state.lastSavedTime}
                            lastPublishedTime={this.state.lastPublishedTime}
                            publishAllEntries={this.publishAllEntries}
                            publishing={this.state.publishing} />
                    </fieldset>
                </div>

            )
        } else {
            return (
                <div className="user-forms dlas-forms">
                    <LoaderComponent className="center-spinner" data={this.state.isLoaded} />
                </div>
            )
        }
    }
}

const getItemStyle = (isDisabled, isDragging, draggableStyle, pendingDeletion) => ({
    userSelect: 'none',
    background: isDisabled ? '#c7c7c7' : isDragging ? '#34495e' : 'white',
    color: isDisabled ? 'black' : isDragging ? 'white' : '#34495e',
    border: pendingDeletion ? 'red' : 'none',
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#8b9fb3' : '#666666',
    width: '100%',
});

class MinutesIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCalendarCategory: ''
        }

        this.handleCalendarCategoryChange = this.handleCalendarCategoryChange.bind(this);
        this.createCategory = this.createCategory.bind(this);
    }
    handleCalendarCategoryChange(value) {
        this.setState({
            selectedCalendarCategory: value
        });
    }

    async createCategory() {
        if (this.state.selectedCalendarCategory.CategoryType === "Chairmans Report" && this.props.categories.find(c => c.CalendarCategoryTypeID === this.state.selectedCalendarCategory.CalendarCategoryTypeID)) {
            await this.props.duplicateChairmansCategory(this.state.selectedCalendarCategory.CalendarCategoryTypeID);
            this.setState({
                selectedCalendarCategory: ''
            });
        } else {
            const newCategory = {
                ...this.state.selectedCalendarCategory,
                CategoryDescription: this.state.selectedCalendarCategory.Description,
                ReleaseToPreview: true,
                Sequence: this.props.categories.length + 1
            };
            this.setState({
                selectedCalendarCategory: ''
            });
            await this.props.createCategory(newCategory);
        }
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = (() => {
            const list = Array.from(this.props.categories);
            const [removed] = list.splice(result.source.index, 1);

            list.splice(result.destination.index, 0, removed);
            // Reorder the Sequence value too because the sequence value is used to show what order the preview is in
            list.forEach(function (element, index) {
                element.Sequence = index + 1
            });
            return list;
        })();
        //Send the new values up to the parent to be set in the parent's state
        this.props.onSequenceChange(items);
    }


    render() {
        if (this.props.categories) {
            return (
                <div className={this.props.disableChanges ? 'dnd-disabled' : ''}>
                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <table
                                    className="dnd-grid-table minutes-categories-grid"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <thead className="dnd-grid-header" style={getListStyle(snapshot.isDraggingOver)}>
                                        <tr>
                                            <th scope="col" id={'-grabber-column'}></th>
                                            <th scope="col" id={'-display-column'}>Display #</th>
                                            <th scope="col" id={'-display-column'}>Include</th>
                                            <th scope="col" id={'-display-column'}>Section</th>
                                            <th scope="col" id={'-actions-column'}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.categories.map((item, index) => (
                                            <Draggable key={`${item.Sequence}-${item.MinutesCategoryID}`} draggableId={`${item.Sequence}-${item.MinutesCategoryID}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={this.props.categories[this.props.currentActivitySequence - 1] === item ? 'selected' : 'not-selected'}
                                                        style={getItemStyle(
                                                            this.props.disableChanges,
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )} >
                                                        <td><button id={item.MinutesCategoryID + '-grabber-button'} className="button draggable">Drag and drop</button></td>
                                                        <td>{index + 1}</td>
                                                        <td><input type="checkbox" checked={this.props.categories[index].ReleaseToPreview === true} onChange={() => this.props.showPreview(index)} /></td>
                                                        <td className='category-desc' onClick={(e) => this.props.showActivity(this.props.categories[index], e)}>
                                                            {this.renderCategoryDescription(item)}
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                onClick={(e) => this.props.showActivity(this.props.categories[index], e)}
                                                                className={this.props.categories[this.props.currentActivitySequence - 1] === item
                                                                    ? 'icon edit-alt'
                                                                    : 'icon edit'}>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        <tr style={getItemStyle(false)} >
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="category-desc">
                                                <div style={{ width: '80%' }}>
                                                    <Select
                                                        value={this.state.selectedCalendarCategory}
                                                        onChange={this.handleCalendarCategoryChange}
                                                        options={this.props.calendarCategories}
                                                        getOptionLabel={option => (option.DisplayType ? option.CategoryType + ' - ' : '') + option.Description}
                                                        getOptionValue={option => option.CalendarCategoryTypeID}
                                                    />
                                                </div>
                                            </td>
                                            <td><button disabled={this.props.creatingCategory || !this.state.selectedCalendarCategory} onClick={async () => await this.createCategory()} type="button" className="button small-button">{this.props.creatingCategory ? 'Adding' : 'Add'}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    //Some categories get the type pre-pended to the description while other do not
    renderCategoryDescription(item) {
        //The reason why buttons are in here is because the form saves when an input is blurred. The only way to blur something is to put focus on something else
        // and td elements are not "focus-able", but buttons are.
        return (<button type="button">{this.props.getCategoryDescriptionText(item)}</button>);
    }
}

class Preview extends React.Component {
    render() {
        const previewLengthStyle = { height: '500px', overflowY: 'scroll', backgroundColor: 'white' }
        const newPreviewArray = this.props.categories.filter(
            entryObj => entryObj.ReleaseToPreview
        );
        return (
            <React.Fragment>
                <div className="top-right">
                    {this.props.isPendingChange ?
                        <span className="pending-label">'Pending...'</span>
                        :
                        <React.Fragment>
                            <span className="last-saved-label">{this.props.lastPublishedTime && !this.props.lastSaveErr ? 'Last published at ' + this.props.lastPublishedTime.format("HH:mm:ss") + " |" : ''}</span>
                            {this.props.lastSaveErr && <span className="pending-label">{this.props.lastSaveErr}</span>}
                            <span className="last-saved-label">{this.props.lastSavedTime ? 'Last saved at ' + this.props.lastSavedTime.format("HH:mm:ss") : ''}</span>
                        </React.Fragment>
                    }
                    <button type="button" disabled={this.props.publishing} className="button" onClick={this.props.publishAllEntries}>Publish</button>
                </div>
                <div style={previewLengthStyle}>
                    <MinutesDetails
                        categories={newPreviewArray}
                        publicPage={false}
                    />
                </div>
            </React.Fragment>
        )
    }
}

const TypeSwitcher = React.forwardRef((props, ref) => {
    switch (props.type) {
        case ('Order'):
        case ('Communication'):
        case ('General'):
            //The following should be treated as a legislative category:
            // - Unfinished Business (61, 62, 63)
            // - For Immediate Consideration (151)
            // - Bills in Conference (72)
            // - Senate Bills with Governor's Recommendation (111)
            // - House Bills with Governor's Recommendation (112)
            if ([61, 62, 63, 151, 72, 111, 112].includes(props.formData.CalendarCategoryTypeID)) {
                return (<div><LegislativeForm ref={ref} {...props} /></div>);
            }
        case ('Chairmans Report'):
            return (<div><NonLegislativeForm ref={ref} {...props} /></div>);
        case ('Regular Calendar'):
        case ('Uncontested Calendar'):
        case ('Notification'):
            return (<div><LegislativeForm ref={ref} {...props} /></div>);
        default:
            return (<div><Misc ref={ref} {...props} /></div>);
    }
});

export default connect(
    (state) => {
        const { minutes, calendar, nav, members, committee, communications, bills, session } = state;
        return {
            minutes,
            calendar,
            nav,
            members,
            committee,
            communications,
            bills,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, minutesActionCreators, calendarActionCreators, navActionCreators, memberActionCreators, committeeActionCreators, communicationsActionCreators, billActionCreators, sessionActionCreators), dispatch)
        }
    }
)(MinutesManagementForm)